<!-- 车主服务 -->
<template>
  <div class="Service">
    <div class="title">车主服务</div>
    <div class="listwrap">
      <div class="item" v-for="item in cellList" :key="item.title" @click="nextPage(item)">
        <img class="icon" :src="item.icon" alt="" />
        <p class="txt">{{ item.title }}</p>
        <img class="right" src="@/assets/images/right_arrow.png" alt="" />
      </div>
    </div>
    <ImgSwiper v-if="bottomBanner.length" :bannerList="bottomBanner" />
    <Footer :active="2" />
    <PopupModal :showModal="showModal" :content="modalContent" :confirmClick="hideModalAndNextPage" :cancelClick="hideModal"></PopupModal>
  </div>
</template>

<script>
import ImgSwiper from "@/components/Swiper.vue";
import PopupModal from "@/components/PopupModal.vue";
import { getBannerList } from "@/api/api_home";
import { mapState } from "vuex";
export default {
  name: "Service",
  data () {
    return {
      bottomBanner: [],
      cellList: [
        {
          title: "融资计算",
          routeName: "Calculator",
          icon: require("@/assets/images/my_and_service/service_1.png")
        },
        {
          title: "卖车估值",
          src: "https://m.taoche.com/app/sell?hideType=7&from=3686993",
          icon: require("@/assets/images/my_and_service/service_2.png")
        }
      ],
      showModal: false,
      modalContent:
        "您即将跳转到第三方应用平台，该平台服务由淘车提供，相关服务和责任由淘车承担，如有疑问请咨询淘车客服"
    };
  },
  computed: {
    ...mapState(["position"])
  },
  created () {
    this.getImgList();
  },
  methods: {
    // 获取广告
    getImgList () {
      getBannerList({
        cityCode: this.position.cityId,
        adPage: 6
      }).then(res => {
        if (res.code === 0) {
          const info = res.data.info;
          this.bottomBanner = info.bottomBanner;
        }
      });
    },
    hideModal () {
      this.showModal = false;
    },
    hideModalAndNextPage () {
      this.showModal = false;
      location.href = this.cellList[1].src;
    },
    nextPage (item) {
      if (item.routeName) {
        this.$router.push({ name: item.routeName });
      } else if (item.title === "卖车估值") {
        this.showModal = true;
      } else {
        location.href = item.src;
      }
    }
  },
  components: { ImgSwiper, PopupModal }
};
</script>
<style lang="scss" scoped>
.Service {
  .title {
    font-size: 0.3rem;
    font-weight: bold;
    line-height: 0.42rem;
    color: $mainBlack;
    padding: 0.45rem 0.18rem 0.08rem;
    background: #fff;
  }
  .listwrap {
    margin: 0.1rem 0.18rem;
    border-radius: 0.08rem;
    padding: 0 0.2rem;
    background: #fff;
  }
  .item {
    height: 0.55rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f1f2f6;
    .icon {
      width: 0.18rem;
      height: 0.18rem;
      margin-right: 0.17rem;
    }
    .txt {
      flex: 1;
      font-size: 0.15rem;
      color: $mainBlack;
    }
    .right {
      width: 0.07rem;
    }
  }
}
</style>
