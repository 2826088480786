<!-- 首页轮播图 -->
<template>
  <div class="homeSwiper" ref="homeSwiper">
    <van-swipe :autoplay="3000" :height="height">
      <van-swipe-item v-for="(image, index) in bannerList" :key="index" @click="nextPage(image.adUrl)">
        <van-image fit="contain" lazy-load :src="image.photoUrl" :show-error="false" :show-loading="false">
        </van-image>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "homeSwiper",
  props: {
    bannerList: {
      type: Array
    }
  },
  data () {
    return {
      height: 130
    };
  },
  computed: {
    ...mapState(["loginInfo"])
  },
  mounted () {
    this.height = (160 / 414) * this.$refs.homeSwiper.offsetWidth;
  },
  methods: {
    nextPage (image) {
      //该页面需要登陆
      if (!this.loginInfo.userId) {
        this.$router.push({
          name: "Login",
          params: {
            name: "HOME"
          }
        });
        return;
      }
      location.href = image;
    }
  }
};
</script>
<style lang="scss">
.homeSwiper {
  margin: 0 0.18rem;
  border-radius: 0.08rem;
  overflow: hidden;
}
</style>
